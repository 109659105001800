import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter/src/TypesenseInstantsearchAdapter"
import { additionalSearchParameters } from "@jobshop/crossroads/utils/search/additionalSearchParameters"
import { useTypesenseApiKey } from "~/composables/useTypesenseApiKey"

export async function useTypesenseSearchClient() {
  const { public: { typesenseUrl } } = useRuntimeConfig()
  const { typesenseApiKey, typesenseApiKeyPending, typesenseApiKeyError } = await useTypesenseApiKey()

  // create typesense search client
  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: typesenseApiKey.value,
      nodes: [
        {
          url: typesenseUrl,
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60,
    },
    additionalSearchParameters,
  })
  const searchClient = ref(typesenseInstantsearchAdapter.searchClient)

  return { searchClient }
}
